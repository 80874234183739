.Praxis {
  font-family: Merriweather;
  display: flex;
  flex-direction: column;
}
body {
  margin: 0;
  padding: 0;
}
.titelmobile{
  color: #7e2b2b;
  font-style: italic;
  padding-left: 90px;
  font-size: 13px;
  padding-top: 5px;
  padding-bottom: 0px;
  display: flex;
  background-color: rgba(185, 93, 99, 0.22);
}
.titel {
  color: #7e2b2b;
  font-style: italic;
  padding-left: 138px;
  font-size: 22px;
  padding-top: 5px;
  padding-bottom: 0px;
  display: flex;
  background-color: rgba(185, 93, 99, 0.22);
}
.footer {
  background-color: rgba(185, 93, 99, 0.22);
  color: #7e2b2b;
  bottom: 20px;
  padding: 8px;
}
.footer2 {
  text-align: center;
  font-size: 13px;
}
.footer a{
  text-decoration: none;
  color: #7e2b2b;
}
.footer a:hover {
  font-weight: bold;
  color: #6c2929;
}
.mobileheader{
  position: sticky;
  top: 0;
  background-color: white;
}
.mobile{
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 1px;
  background-color: rgba(185, 93, 99, 0.22);
}
.mobile li{
  float: left;
  text-align: center;
  padding-left: 0px;
  line-height: 8px;
  font-size: x-small;
}
.mobile li a{
  display: flex;
  color: #7e2b2b;
  text-align: center;
  padding: 13px 13px;
  text-decoration: none;
  padding-left: 15px;
}
.header{
  position: sticky;
  top: 0;
  background-color: white;
}
.header ul{
  list-style-type: none;
  margin: 0;
  overflow: hidden;
  padding-left: 170px;
  background-color: rgba(185, 93, 99, 0.22);
}
.header li {
  float: left;
  text-align: left;
  padding-left: 0px;
  line-height: 12px;
}
.logoheadermobile{
  float: left;
  width: 60px;
  overflow: hidden;
  margin-top: 2px;
  padding-left: 25px;
  position: absolute;
}
.logoheader {
  float: left;
  width: 93px;
  overflow: hidden;
  margin-top: 2px;
  padding-left: 45px;
  position: absolute;
}

.box {
  float: left;
  background-color: rgba(185, 93, 99, 0.22);
  width: 60px;
}

li a {
  display: flex;
  color: #7e2b2b;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  padding-left: 24px;
}


li a:hover {
  background-color: rgba(222, 177, 179, 0.35);
}
.starttextmobiles{
  text-align: center;
  justify-content: center;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 40px;
  width: 75%;
  font-size: x-small;
}
.starttextmobile{
  text-align: center;
  justify-content: center;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 40px;
  width: 75%;
  font-size: small;
}
.starttext {
  text-align: center;
  justify-content: center;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 40px;
  width: 800px;
  font-size: 17px;
}
.start{
  padding-bottom: 20px;
  display: grid;

}
.startbild {
}

.wasmmobile {
  width: 70%;
  padding-left: 15%;
  padding-top: 30px;
  position: relative;
  text-align: center;
  line-height: 22px;
  padding-bottom: 20%;
  font-size: 12px;
}
.wasm {
  width: 40%;
  padding-left: 30%;
  padding-top: 30px;
  position: relative;
  text-align: center;
  line-height: 26px;
  font-size: 17px;
}

.wasmachen {
  width: fit-content;
  font-size: large;
  background-color: rgba(185, 93, 99, 0.22);
  padding: 8px;
  padding-right: 270px;
  border-radius: 10px;
}
.übertitelmobile {
  background-color: rgba(185, 93, 99, 0.22);
  font-size: 12px;
  left: 2%;
  padding-left: 0px;
  position: relative;
  top: 10px;
  color: #7e2b2b;
  text-align: center;
  padding-top: 0px;
  width: 96%;
  padding-bottom: 0px;
  line-height: 35px;
  border-radius: 10px;
}
.übertitel {
  background-color: rgba(185, 93, 99, 0.22);
  font-size: 17px;
  left: 70px;
  padding-left: 10px;
  position: relative;
  top: 33px;
  color: #7e2b2b;
  text-align: center;
  padding-top: 0px;
  width: 90%;
  padding-bottom: 0px;
  line-height: 60px;
  border-radius: 10px;
}
.uebergridmobile {
  display: grid;
  grid-template-columns: 50% 50%;
}
.uebergrid  {
  display: grid;
  grid-template-columns: 48% 48%;
  padding-left: 50px;
}
.uebergridmobile h2 {
  background-color: rgba(185, 93, 99, 0.22);
  padding-left: 4%;
  width: 98%;
  color: #7e2b2b;;
  padding-top: 7px;
  padding-bottom: 10px;
  position: relative;
  line-height: 10px;
  font-size: x-small;
  border-radius: 8px;
}
.uebergrid h4{
  background-color: rgba(185, 93, 99, 0.22);
  padding-left: 10px;
  width: 100%;
  color: #7e2b2b;;
  padding-top: 7px;
  padding-bottom: 10px;
  position: relative;
  line-height: 30px;
  border-radius: 13px;
}
.uebergroß{
  background-color: rgba(185, 93, 99, 0.22);
  padding-left: 10px;
  top: 33px;
  width: 600px;
  padding-top: 0px;
  padding-bottom: 20px;
  position: relative;
  line-height: 25px;
  border-radius: 13px;
}
.teambildmobile {
  float: right;
  margin: 0;
  padding-top: 0px;
  padding-left: 2px;
}
.teambild {
  float: right;
  margin: 0;
  padding-top: 10px;
  padding-left: 5px;
}
.Porträtmobile {
  float: left;
  line-height: 13px;
  padding: 10px;
  padding-top: 0px;
  font-size: 9px;
}
.Porträt {
  float: left;
  line-height: 26px;
  padding: 20px;
  font-size: 17px;
}
.albummobile {
  position: relative;
  color: #7e2b2b;
  left: 15%;
  bottom: 20px;
  width: 70%;
}
.album {
  position: absolute;
  top: 480%;
  bottom: 30px;
  color: #7e2b2b;
  left: 15%;
  width: 70%;
}
.stitelmobile {
  background-color: rgba(185, 93, 99, 0.22);
  left: 8%;
  padding-left: 10px;
  padding-right: 10px;
  top: 33px;
  text-align: center;
  padding-top: 5px;
  width: 80%;
  padding-bottom: 5px;
  font-size: 11px;
  position: relative;
  line-height: 20px;
  border-radius: 13px;
}
.stitelmobile h3{
  color: #7e2b2b;
}
.stoerungtitel {
  background-color: rgba(185, 93, 99, 0.22);
  left: 90px;
  padding-left: 10px;
  padding-right: 10px;
  top: 33px;
  text-align: center;
  padding-top: 5px;
  width: 86.5%;
  padding-bottom: 5px;
  font-size: 17px;
  position: relative;
  line-height: 30px;
  border-radius: 13px;
}
.stoerungtitel h3{
  color: #7e2b2b;
}
.stoerungmobile {
  float: left;
  padding-left: 6%;
  width: 90%;
  padding-top: 30px;
  font-size: xx-small;
  line-height: 12px;
  position: relative;
}
.stoerungmobile ul{
  float: left;
  padding: 20px;
}
.stoerung {
  float: left;
  padding-left: 6%;
  width: 80%;
  padding-top: 30px;
  line-height: 26px;
  position: relative;
}
.stoerung ul{
  float: left;
  padding-left: 20px;
}
.grid-containermobile{
  display: grid;
  grid-template-columns: 48% 48%;
}
.grid-container{
  display: grid;
  grid-template-columns: 38% 38% 38%;
}
.stoerungmobile p{
  background-color: rgba(185, 93, 99, 0.22);
  padding: 4px;
  top: 10px;
  width: 100%;
  padding-top: 0px;
  bottom: 3px;
  padding-bottom: 10px;
  position: relative;
  line-height: 10px;
  border-radius: 13px;
}
.stoerung p{
  background-color: rgba(185, 93, 99, 0.22);
  padding-left: 10px;
  top: 33px;
  width: 370px;
  padding-top: 0px;
  padding-bottom: 10px;
  position: relative;
  line-height: 18px;
  border-radius: 13px;
}
.grid-itemmobile{
  border: 2px;
  padding-left: 6px;
  padding-right: 6px;
}
.grid-itemmobile ul{
  padding-top: 2px;
  padding-bottom: 2px;
}
.grid-item{
  border: 2px
}
.koopmobile{
  padding-top: 20px;
  width: 60%;
  font-size: 13px;
  text-align: center;
  padding-left: 20%;
  position: relative;
}
.koop{
  padding-top: 20px;
  width: 40%;
  font-size: 17px;
  text-align: center;
  padding-left: 30%;
  position: relative;
}
.kooperationmobile{
  background-color: rgba(185, 93, 99, 0.22);
  width: 80%;
  left: 10%;
  border-radius: 13px;
  padding: 2px;
  position: relative;
}
.kooperationmobile a {
  color: black;
  font-size: 11px;
}
.kooperation{
  background-color: rgba(185, 93, 99, 0.22);
  width: 35%;
  left: 32%;
  border-radius: 13px;
  padding: 5px;
  position: relative;
}
.kooperation a{
  color: black;
  font-size: 17px;
}
.kooperationmobile a:hover{
  color: #7e2b2b;
}
.kooperation a:hover{
  color: #7e2b2b;
}
.stoerungendemobile{
  background-color: rgba(185, 93, 99, 0.22);
  left: 3%;
  padding-right: 10px;
  top: 0px;
  text-align: center;
  padding-top: 5px;
  width: 90%;
  padding-bottom: 5px;
  font-size: 12px;
  position: relative;
  line-height: 20px;
  border-radius: 13px;
}
.stoerungende{
  background-color: rgba(185, 93, 99, 0.22);
  left: 90px;
  padding-left: 10px;
  padding-right: 10px;
  top: 33px;
  text-align: center;
  padding-top: 5px;
  width: 90%;
  padding-bottom: 5px;
  font-size: 17px;
  position: relative;
  line-height: 30px;
  border-radius: 13px;
}


